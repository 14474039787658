.ticket-group {
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  &.ticket-group--big-spacing {
    margin-bottom: 60px;
  }

  &.ticket-group--all {
    .ticket:nth-child(n+4) {
      display: grid;
    }
  }

  .ticket:nth-child(n+4) {
    display: none;
  }
}
