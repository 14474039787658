@import "../../css/lib/rem-calc";

.ticket {
  display: grid;
  grid-template-columns: 1fr 50px;
  height: 100px;
  background-color: #fff;
  box-shadow: 0px 3px 0px #DFDFDF;
  transition: box-shadow .3s;
  box-sizing: border-box;
  outline: none;

  &:hover {
    box-shadow: 0px 5px 0px #DFDFDF;
  }

  &.ticket--selected {
    border: 1px solid #000;
    background-color: #F6F5F1;
    box-shadow: none;

    .ticket__button {
      background-color: #F6F5F1;
    }
  }

  .ticket__info {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 20px;
  }

  .ticket__main {
    text-align: left;
  }

  .ticket__location {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(18px);
    line-height: 120%;
    letter-spacing: -0.01em;
  }

  .ticket__info-icon {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .ticket__age {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(14px);
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #49525A;
  }

  .ticket__prices {
    text-align: right;
  }

  .ticket__old-price {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 400;
    @include fontSize(14px);
    line-height: 100%;
    text-align: right;
    letter-spacing: -0.01em;
    text-decoration-line: line-through;
    color: #859095;
  }

  .ticket__price {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(18px);
    line-height: 120%;
    letter-spacing: -0.01em;

    &.ticket__price--discounted {
      @include fontSize(16px);;
      color: #5e9b6c;
    }
  }

  .ticket__amount {
    font-weight: 500;
    color: #000;
  }

  .ticket__price-help {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(12px);
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #49525A;
  }

  .ticket__buttons {
    border-left: 1px solid #D2D2CE;
    box-sizing: border-box;
  }

  .ticket__button {
    height: 50px;
    width: 49px;
    border: 0;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #f3f3f3;
    }
  }

  .ticket__add,
  .ticket__substract {
    width: 13px;
    height: 13px;
  }

  .ticket__button--minus {
    border-top: 1px solid #D2D2CE;
    height: 48px;
  }
}
