@import "../../css/lib/rem-calc";

.subtotal {
  padding: 0 20px 30px;

  .subtotal__divider {
    border: 0;
    border-top: 1px solid #859095;
  }

  .subtotal__total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(20px);
    line-height: 110%;
    color: #000000;
    margin-bottom: 17px;
  }

  .subtotal__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .subtotal__price-container {
    display: flex;
  }

  .subtotal__text-line,
  .subtotal__price-line {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(14px);
    line-height: 114.285714285714285%;
    color: #000000;
    text-align: left;

    &.subtotal__price-line--colored {
      color: #5E9B6C;
    }
  }

  .subtotal__old-price-line {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(12px);
    line-height: 13px;
    text-align: right;
    text-decoration-line: line-through;
    color: #8C8C8C;
    margin-right: 4px;
  }

  .subtotal__next-message {
    color: #888;
    @include fontSize(14px);
    padding: 0;
    box-sizing: border-box;
    margin: 32px 0;
    line-height: 1.5;
    transition: opacity 300ms, line-height 400ms;

    &.subtotal__next-message--hidden {
      line-height: 0;
      opacity: 0;
    }
  }
}
