@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.intro {
  position: relative;
  height: 160px;

  @media (min-width: $default-breakpoint) {
    padding: 45px 70px;
    height: auto;
  }

  &.intro--spaced {
    padding: 0;
    height: 250px;
    @media (min-width: $default-breakpoint) {
      padding: 50px 70px 60px;
      height: auto;
    }
  }

  &.intro--dark {
    .intro__container {
      background: none;
    }

    .intro__title {
      color: #000;
    }
  }

  &.intro--light {
    .intro__title {
      color: #fff;

      @media (min-width: $default-breakpoint) {
        color: #000;
      }
    }
  }

  .intro__container {
    background: none;
    position: absolute;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: $default-breakpoint) {
      position: static;
      width: auto;
      left: auto;
      top: auto;
      transform: translate(0);
    }
  }

  .intro__title {
    margin-top: 0;
    margin-bottom: 0;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(22px);
    line-height: 113.636363636363635%;
    text-align: center;
  }

  .intro__help-text + .intro__title {
    margin-bottom: 18px;
  }

  .intro__help-text {
    color: #5E9B6C;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(13.5373px);
    line-height: 100%;
    text-align: center;
  }
}

.page-wrapper--background-image  {
  .intro__container {
    background: radial-gradient(closest-side, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);

    @media (min-width: $default-breakpoint) {
      background: none;
    }
  }

  .intro__title {
    color: #fff;

    @media (min-width: $default-breakpoint) {
      color: #000;
    }
  }
}
