@import "../../css/lib/rem-calc";
@import "../../css/lib/button-mixin";

.text-block {
  // @include buttonBase;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  padding: 0;

  &:last-child {
    margin-bottom: 20px;
  }

  .text-block__text {
    padding: 20px;
    text-align: left;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    color: #000000;
    letter-spacing: -0.01em;
  }

  .text-block__text-light {
    padding: 20px;
    text-align: left;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    color: #000000;
    letter-spacing: -0.01em;
  }
}
