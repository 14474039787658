@import "../../css/lib/rem-calc";
@import "../../css/lib/button-mixin";

.coupon {
  padding: 10px;
  margin-top: 45px;

  .coupon__text {
    margin-top: 0;
    margin-bottom: 15px;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    font-size: fontSize(14px);
    line-height: 113.6363636364%;
    text-align: center;
  }

  .coupon__container {
    position: relative;
  }

  .coupon__input-delete {
    position: absolute;
    top: 21px;
    right: 21px;
    height: 14px;
    width: 14px;
    cursor: pointer;
  }

  .coupon__input {
    height: 54px;
    margin-bottom: 11px;
    padding: 15px 30px 15px 20px;
    background-color: #fff;
    border: 0;
    color: #000;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    letter-spacing: -0.01em;
    text-align: left;
    outline:none;
    cursor: text;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #fff;
    box-shadow: 0px 3px 0px #DFDFDF;
    transition: box-shadow .3s;
    outline: none;

    &:focus,
    &.coupon__input--selected {
      background-color: #F6F5F1;
      color: #000;
      border: 1px solid #000;
      box-shadow: none;
      outline:none;
    }

    &:invalid {
      border: 1px solid #DD5A42;
    }
  }

  .coupon__button {
    @include colouredButtonBase;
    font-family: "omnes-pro";
    display: block;
    width: calc(100% - 20px);
    margin: 0 10px;
  }
}
