@import "./lib/responsive";


.container {
  -webkit-overflow-scrolling: touch;
  background-color: transparent;
  box-sizing: border-box;
  min-height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $default-breakpoint) {
    background-color: #f6f5f1;
    border-radius: 8px;

    align-items: flex-end;
    box-sizing: border-box;
    min-height: 0px;
    height: 0;
    position: relative;
    transition: height 450ms ease-in-out;
    scroll-behavior: smooth;
    width: 350px;

    &__container {
      font-size: 0;
      position: relative;
    }

    .simplebar-wrapper {
      max-width: 350px + 11px;
      width: 350px + 11px;
    }

    .simplebar-mask {
      left: calc((100vw - 200px - 350px) * -1);
      width: 100vw!important;
    }

    .simplebar-content-wrapper  {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      padding-right: 200px;
    }

    .simplebar-content {
      max-width: 350px;
    }

    .simplebar-track.simplebar-vertical {
      right: -11px;
    }

    .simplebar-scrollbar:before {
      background-color: #f6f5f1;
      opacity: 1;
    }
  }

  .container__container > div {
    font-size: 1rem;
    width: 100%;
  }

  @media (min-width: $default-breakpoint) {
    > * {
      max-width: 350px;
    }

    .footer {
      position: relative;
     }
  }
}
