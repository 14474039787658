@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.scroll-help {
  color: #FFF;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  display: none;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0, 0.5) 100%);
  max-width: 350px;
  padding: 40px 40px 10px;
  z-index: 2;

  @media (min-width: $default-breakpoint) {
    animation: cssAnimation 300ms ease-in forwards;
    animation-delay: 5s;
    display: flex;
    right: 200px;
  }

  &.scroll-help--hidden {
    display: none !important;
  }

  &.scroll-help--animation {
    @media (min-width: $default-breakpoint) {
      animation-delay: 0s;
    }
  }

  .scroll-help__icon {
    width: 18px;
    height: 37px;
    position: relative;
  }

  .scroll-help__text {
    font-family: omnes-pro;
    font-style: normal;
    @include fontSize(16px);
    line-height: 112.5%;
    position: relative;
    text-shadow: 0 0 2px rgba(0,0,0,0.8);
  }
}

@keyframes cssAnimation {
  0% {opacity:1;}
  100% {opacity: 0;}
}
