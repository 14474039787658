@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include fontSize(10px);

  &.breadcrumbs--desktop {
    display: none;

    @media (min-width: $default-breakpoint) {
      padding-top: 28px;
      display: flex;
    }
  }

  &.breadcrumbs--mobile {
    @media (min-width: $default-breakpoint) {
      display: none;
    }
  }

  &.breadcrumbs--confirmation {
    .breadcrumb-item {
      display: flex;
      flex-direction: row;
      line-height: 100%;

      .breadcrumbs__icon {
        margin-right: 8px;
        stroke: #fff;

        @media (min-width: $default-breakpoint) {
          stroke: #000;
        }
      }
    }
  }

  .breadcrumbs__icon {
    height: 12px;
    width: 12px;
    min-height: 12px;
    stroke: #000;
  }

  .breadcrumb-divider {
    opacity: 0.4;
    border-top: 1px solid #000000;
    width: 35px;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 5px;
  }

  .breadcrumb-item {
    opacity: .4;
    color: #000000;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(11px);
    line-height: 127.27272727272727%;
    text-align: center;
    letter-spacing: 0.02em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .breadcrumb-item--selected {
    opacity: 1;
    cursor: pointer;

    .breadcrumbs__icon {
      fill: #000;
      stroke: none;
    }
  }

  .breadcrumb-item--done {
    opacity: 1;
    position: relative;
    cursor: pointer;

    .breadcrumbs__check {
      display: block;
      stroke: #ffffff;
      position: absolute;
      width: 6px;
      height: 6px;
      top: 4px;
      left: calc(50% - 3px);

      @media (min-width: $default-breakpoint) {
        stroke: #000000;
      }
    }
  }
}

.page-wrapper--background-image .breadcrumb-divider {
  border-color: #ffffff;

  @media (min-width: $default-breakpoint) {
    border-color: #000;
  }
}

.page-wrapper--background-image .breadcrumb-item {
  color: #ffffff;

  .breadcrumbs__icon {
    stroke: #fff;
  }

  &.breadcrumb-item--selected .breadcrumbs__icon {
    fill: #fff;
    stroke: none;
  }

  @media (min-width: $default-breakpoint) {
    color: #000;

    .breadcrumbs__icon {
      stroke: #000;
    }

    &.breadcrumb-item--selected .breadcrumbs__icon {
      fill: #000;
      stroke: none;
    }
  }
}
