@import "./lib/responsive";

.main {
  @media (min-width: $default-breakpoint) {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100vh;
    justify-content: flex-end;
    margin-top: -94px;
    max-height: 100vh;
    overflow: hidden;
    padding-right: 200px;

    .fake {
      width: 200px;
    }
  }
}
