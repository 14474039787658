@import "../../css/lib/rem-calc";
@import "../../css/lib/button-mixin";

.loading-button {
  @include colouredButtonBase;
  font-family: omnes-pro;
  font-style: normal;
  font-weight: 500;
  @include fontSize(17px);
  line-height: 111.76470588235294%;

  &.loading-button--disabled {
    cursor: not-allowed;
    background-color: #F5F5F5;
    border: 2px solid #DADADA;
    color: #CECECE;
    box-shadow: none;
    transform: translateY(0px);
  }

  &.active {
    .loading-button__progress {
      height: 5px;
      opacity: 1;
      animation: lv-line_animation 3s ease-in-out infinite;
    }
  }

  .loading-button__progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 0;
    background-color: darken(#5E9B6C, 10);
  }
}

@keyframes lv-line_animation {
  0% {
    left: 0;
    width: 0;
  }
  25% {
    left: 0;
    width: 100%;
  }
  50% {
    left: 100%;
    width: 0;
  }
  75% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 0;
    width: 0;
  }
}
