@import "../../css/lib/rem-calc";

.input,
.input-field {
  border: 1px solid #fff;
  box-shadow: 0px 3px 0px #dfdfdf;
  transition: box-shadow 0.3s;
  position: relative;
  height: 64px;
  outline: none;

  &.input--filled {
    .input__input {
      background-color: #f6f5f1;
      color: #000;
      border: 1px solid #000;
      box-shadow: none;
      outline: none;
      padding: 28px 20px 12px 20px;

      &:invalid,
      &--invalid {
        border: 1px solid #DD5A42;

        & ~ .input__label:before {
          content: var(--invalidText);
          color: #DD5A42;
        }
      }

      ~ .input__label {
        top: 8px;
        @include fontSize(12px);
      }
    }
  }

  .input__input {
    height: 64px;
    padding: 20px;
    background-color: #fff;
    border: 0;
    color: #000;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    letter-spacing: -0.01em;
    text-align: left;
    outline: none;
    cursor: text;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;

    &:focus {
      background-color: #f6f5f1;
      color: #000;
      border: 1px solid #000;
      box-shadow: none;
      outline: none;
      padding: 28px 20px 12px 20px;

      ~ .input__label {
        top: 8px;
        @include fontSize(12px);
      }
    }
  }

  .input__label {
    color: #000;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    letter-spacing: -0.01em;
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 20px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  &:hover {
    box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.05);
  }

  .input__dropdown {
    display: none;
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0px;
    z-index: 1;
    height: 300px;
    overflow: auto;

    &.input__dropdown--open {
      display: block;
      margin-top: 14px;
    }
  }

  .input__item {
    padding: 14px 19px;
    background-color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 110px 10px;
    grid-gap: 17px;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.input__item--hidden {
      display: none;
    }

    &:hover,
    &.input__item--highlight {
      background-color: #f6f5f1;
    }

    &:active,
    &.input__item--selected {
      border: 1px solid #000;
    }
  }

  .input__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
