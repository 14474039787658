@import "./lib/rem-calc";

.hidden {
  opacity: 0;
  min-height: 150px;
}

.flatpickr-input {
  display: none;
}

.flatpickr-calendar.inline {
  margin: 56px 10px 0;
  width: calc(100% - 20px);
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: 0px 3.19355px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s;
}

.flatpickr-calendar.inline .flatpickr-months {
  padding: 30px;
}

.flatpickr-monthDropdown-months,
.flatpickr-current-month span.cur-month {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-align: right;
  font-weight: bold;
  font-family: omnes-pro;
  @include fontSize(22px);
  line-height: 146%;
  margin-left: 1ch;

  &:hover {
    background: transparent;
  }
}

.flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  cursor: default;
  font-family: omnes-pro;
  @include fontSize(22px);
  min-width: 119px;
  pointer-events: none;
}

.flatpickr-current-month .arrowDown,
.flatpickr-current-month .arrowUp {
  display: none;
}

.flatpickr-current-month .numInputWrapper {
  width: auto;

  &:hover {
    background: transparent;
  }
}

.flatpickr-months {
  display: grid;
  grid-template-columns: 14px 1fr 14px;
  grid-gap: 10px;
  align-items: center
}

.flatpickr-current-month {
  position: static;
  width: auto;
  height: auto;
  font-size: auto;
  padding: 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  padding: 0;
  min-width: 140px;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  /* top: 33px; */
  padding: 0;
  position: static;
  height: 14px;
  line-height: initial;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: block;
  opacity: 0;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: none;
}

.flatpickr-rContainer {
  width: 100%;
  padding: 20px;
}

.flatpickr-days,
.dayContainer {
  width: 100%;
  max-width: initial;
  min-width: initial;
  justify-content: flex-start;
  overflow: visible;
}

.dayContainer,
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.flatpickr-innerContainer {
  overflow: visible;

  .flatpickr-weekday {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 400;
    @include fontSize(13.53px);
    line-height: 155.21064301552108%;
    color: rgba(0, 0, 0, 0.4);
  }
}

.flatpickr-day {
  font-family: omnes-pro;
  font-style: normal;
  font-weight: 400;
  @include fontSize(18.8571px);
  line-height: 111.36388946338515%;
  letter-spacing: -0.471429px;
  color: #000000;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  width: 40px;

  &[title]:hover::after {
    content: attr(title);
    position: absolute;
    bottom: -100%;
    left: -30px;
    width: 100px;
    background: #2D2717;
    border-radius: 5px;
    font-family: omnes-pro;
    @include fontSize(11px);
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    z-index: 1;
  }
}

.flatpickr-day.flatpickr-disabled {
  font-family: omnes-pro;
  font-style: normal;
  @include fontSize(18.8571px);
  line-height: 111.36388946338515%;
  letter-spacing: -0.471429px;
  text-decoration-line: line-through;
  color: #808080;
  cursor: default;

  &:hover {
    color: #808080;
    cursor: default;
  }
}

.flatpickr-day.today {
  background: #D9E1E6;
  border: 0;
}

.nextMonthDay {
  opacity: 0;
}

.prevMonthDay {
  opacity: 0;
}

.flatpickr-day.selected {
  background: #F6F5F1;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.05);
  color: #000;
}

.flatpickr-day.selected:hover {
  background-color: #e6e6e6;
  color: #000;
  border-color: #000000;
}
