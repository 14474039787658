@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";
@import "../../css/lib/button-mixin";

.block {
  @include buttonBase;
  display: grid;
  grid-template-columns: 120px 1fr;
  padding: 0;

  @media (min-width: $default-breakpoint) {
    grid-template-columns: 1fr;
    height: 125px;
  }

  .block__image {
    width: 120px;
    height: 120px;
    object-fit: cover;

    @media (min-width: $default-breakpoint) {
      display: none;
    }
  }

  .block__texts {
    padding: 47px 30px;
    text-align: left;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(18px);
    line-height: 122.22222222222222%;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (min-width: $default-breakpoint) {
      justify-content: center;
      padding: 49px 30px;
      @include fontSize(20px);
    }

    &.block__texts--small {
      padding: 30px 22px;

      @media (min-width: $default-breakpoint) {
        padding: 49px 30px;
      }
    }

    &.block__texts--image {
      @media (min-width: $default-breakpoint) {
        padding: 38px 30px;

        .block__logo {
          display: block;
        }

        .block__text {
          display: none;
        }
      }
    }
  }

  .block__logo {
    display: none;
    max-height: 50px;
    max-width: 250px;
  }

  .block__text {
    display: block;
  }

  .block__small-text {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 400;
    @include fontSize(14px);
    line-height: 115%;
    color: #474747;
  }

  .block__text-light {
    padding: 47px 30px;
    text-align: left;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(18px);
    line-height: 122.22222222222222%;
    color: #000000;
  }
}
