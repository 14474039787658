@import "./lib/responsive";

.animation {
  background-color: #f6f5f1;
  width: 100%;
  min-width: 100%;
}

.animation-move-up {
  opacity: 0;
  animation: animation-move-up-load 300ms 800ms ease-in forwards;

  @media (min-width: $default-breakpoint) {
    opacity: 1;
    animation: 0;
  }
}

.animation-fade-in {
  opacity: 0;
  animation: animation-fade-in 300ms 1200ms ease-in forwards;

  @media (min-width: $default-breakpoint) {
    opacity: 1;
    animation: 0;
  }
}

@keyframes animation-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation-move-up-load {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-left-enter-active {
  transition: transform 300ms;

  @media (min-width: $default-breakpoint) {
    transition: none;

    .container__container {
      transition: opacity 300ms 200ms;
      opacity: 0;
    }
  }

  &.slide-left-enter-to {
    transform: translateX(-100%);

    @media (min-width: $default-breakpoint) {
      .container__container {
        opacity: 1;
      }
    }
  }
}

.slide-left-leave-active {
  transition: transform 300ms, filter 200ms;

  @media (min-width: $default-breakpoint) {
    transition: none;

    .container__container {
      transition: opacity 500ms;
      opacity: 1;
    }
  }

  &.slide-left-leave-to {
    transform: translateX(-10%);
    filter: brightness(50%);

    @media (min-width: $default-breakpoint) {
      .container__container {
        opacity: 0;
      }
    }
  }
}

// Slide screen to the right (newly ariving screen)
.slide-right-enter-active {
  transform: translateX(-110%);
  transition: transform 300ms;

  @media (min-width: $default-breakpoint) {
    transition: none;
    transform: none;

    .container__container {
      transition: opacity 300ms 200ms;
      opacity: 0;
    }
  }

  &.slide-right-enter-to {
    transform: translateX(-100%);

    @media (min-width: $default-breakpoint) {
      .container__container {
        opacity: 1;
      }
    }
  }
}

// Slide screen to the right (old leaving screen)
.slide-right-leave-active {
  transition: transform 300ms;
  z-index: 1;

  @media (min-width: $default-breakpoint) {
    transition: none;

    .container__container {
      transition: opacity 500ms;
      opacity: 1;
    }
  }

  &.slide-right-leave-to {
    transform: translateX(100%);

    @media (min-width: $default-breakpoint) {
      .container__container {
        opacity: 0;
      }
    }
  }
}


.fade-in-out-enter,
.fade-in-out-leave-to { opacity: 0 }

.fade-in-out-leave,
.fade-in-out-enter-to { opacity: 1 }

.fade-in-out-enter-active,
.fade-in-out-leave-active { transition: opacity 300ms }
