@mixin buttonBase {
  border: 0;
  box-shadow: 0px 3px 0px #DFDFDF;
  transition: box-shadow .3s, transform .2s;
  outline:none;
  cursor: pointer;
  transform: translateY(0px);
  background-color: #fff;
  color: #000;
  padding: 12px 45px;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;

  &:hover {
    box-shadow: 0px 5px 0px #DFDFDF;
    transform: translateY(-2px);
  }

  &:active {
    color: #000;
    box-shadow: 0px 3px 0px #DFDFDF;
    outline:none;
    transform: translateY(0px);
  }

  &:visited {
    box-shadow: 0px -3px 0px #DFDFDF;
    transform: translate(0, 3px);
  }
}

@mixin colouredButtonBase {
  @include buttonBase;
  background-color: #5E9B6C;
  color: #fff;
  box-shadow: 0px 3px 0px rgba(94, 155, 108, 0.2);

  &:hover {
    box-shadow: 0px 5px 0px rgba(94, 155, 108, 0.2);
  }

  &:active {
    background: #5D966A;
    color: #fff;
    border: 1px solid #5D966A;
  }

  &:visited {
    box-shadow: 0px -3px 0px rgba(94, 155, 108, 0.2);
  }
}


@mixin buttonTransparent {
  @include buttonBase;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  box-shadow: none;
  filter: drop-shadow(0px 3px 0px #DFDFDF);

  &:hover {
    box-shadow: none;
    filter: drop-shadow(0px 5px 0px #DFDFDF);
  }

  &:active {
    box-shadow: none;
    filter: drop-shadow(0px 3px 0px #DFDFDF);
  }
}
