@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.errors:not(:empty) {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 10px;
  padding: 0 10px;
  z-index: 1;
  opacity: 1;

  @media (min-width: $default-breakpoint) {
    position: sticky;
  }

  .errors__error {
    padding: 10px 35px 10px 10px;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(14px);
    line-height: 157.14285714285714%;
    letter-spacing: -0.01em;
    background: #DD5A42;
    box-shadow: 0px 3px 0px rgba(133, 144, 149, 0.1);
    position: relative;
  }

  .errors__image {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    padding: 2px;
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid transparent;
    transition: border 0.3s;
    border-radius: 50%;

    &:hover {
      border: 2px solid #000;
    }
  }
}
