@import "../../css/lib/responsive";


.main-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 300ms;

  &.main-image--animate {
    @media (min-width: $default-breakpoint) {
      opacity: 0;
    }
  }

  &.main-image--smaller {
    height: 350px;

    @media (min-width: $default-breakpoint) {
      height: 100vh;
    }
  }

  &.main-image--confirmation {
    height: 530px;

    @media (min-width: $default-breakpoint) {
      height: 100vh;
      position: fixed;
    }
  }
}
