@use "../../css/lib/menu";
@import "../../css/lib/responsive";
@import "../../css/lib/button-mixin";

.menu {
  .menu__burger {
    cursor: pointer;
    width: 32px;
    height: 32px;
    stroke: #000;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 50%;
    transition:  background-color .3s, color .3s;

    &:hover {
      background-color: #fff;
      stroke: #000
    }

    @media (min-width: $default-breakpoint) {
      stroke: #fff;
      position: relative;
      z-index: 1;
    }
  }

  .menu__options {
    @include menu.dropdown();
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    background-color: #f6f5f1;

    @media (min-width: $default-breakpoint) {
      display: flex;
      overflow: hidden;
      top: 10px;
      right: 10px;
      left: auto;
      border-radius: 5px;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      width: 350px;
      padding: 29px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
      z-index: 2;
    }
  }

  .menu__header {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 17px;
    text-align: center;
    justify-content: center;

    @media (min-width: $default-breakpoint) {
      padding: 0;
    }
  }

  .menu__header-text {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding-bottom: 17px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  .menu__header-image {
    position: absolute;
    right: 17px;
    top: 17px;
    width: 24px;
    height: 24px;
    padding: 2px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border .3s;
    border-radius: 50%;
    box-sizing: border-box;

    &:hover {
      border: 2px solid #000;
    }

    @media (min-width: $default-breakpoint) {
      top: 0;
      right: 0;
    }
  }

  .menu__icon {
    width: 13px;
    height: 13px;
  }

  .menu__options-container {
    padding: 0 8px 8px 8px;
    width: calc(100% - 16px);
  }

  .menu__option {
    @include buttonBase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    @media (min-width: $default-breakpoint) {
      padding: 20px;
    }
  }

  .menu__option-image {
    height: 34px;
  }
}

.page-wrapper--background-image .menu__burger {
  stroke: #fff;
}
