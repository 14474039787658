@import "./lib/responsive";

.text-selection {
  position: fixed;
  right: 26px;
  display: none;

  @media (min-width: $default-breakpoint) {
    display: block;
  }

  .text-selection__item {
    background: rgba(0, 0, 0, 0.2);
    color: #F6F5F1;
    border: 2px solid #F6F5F1;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    cursor: pointer;

    &.text-selection__item--active {
      background: #F6F5F1;
      color: #000000;
    }
  }

  .text-selection__smaller {
    font-size: 11.4458px;
  }

  .text-selection__bigger {
    font-size: 18.8038px;
  }
}
