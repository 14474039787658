@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.back {
  display: none;

  @media (min-width: $default-breakpoint) {
    position: absolute;
    top: 18px;
    left: 18px;
    display: block;
  }

  .back__back {
    padding: 8px 5px;
    stroke: #000;
    cursor: pointer;
    height: 32px;
    width: 32px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid transparent;

    @media (min-width: $default-breakpoint) {
      stroke: #000;
    }

    &:hover {
      border: 1px solid #000;
    }
  }
}
