@import "./lib/rem-calc";
@import "./lib/responsive";

.page-wrapper {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding: 0;
    width: 100%;
    background-color: #f6f5f1;

    @media (min-width: $default-breakpoint) {
      background-color: #C4B490;
    }
}

.page-wrapper__background-image {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    transform: scale(1);
    opacity: 1;


    &.page-wrapper__background-image--confirmation {
      height: 530px;
    }
}

.page-wrapper__loading {
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  @include fontSize(25px);
  background-color: rgba(255, 255, 255, .5);
  font-family: omnes-pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-wrapper__content {
    position: relative;
}

.page-wrapper.page-wrapper__background-image {
  height: 350px;
  position: absolute;

  @media (min-width: $default-breakpoint) {
    height: 100vh;
    position: fixed;
  }

  &.page-wrapper__background-image--confirmation {
    height: 530px;

    @media (min-width: $default-breakpoint) {
      height: 100vh;
      position: fixed;
    }
  }
}
