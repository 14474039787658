@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.modal {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  cursor: default;
  transition: transform 400ms;
  transform: translateY(100vh);

  @media (min-width: $modal-breakpoint) {
    border-radius: 8px;
    right: 570px;
    top: 75px;
    bottom: 75px;
    margin: auto 0;
    left: auto;
    width: 330px;
    min-height: 100px;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
  }

  &.modal--open {
    display: block;
    transform: translateY(0);

    @media (min-width: $modal-breakpoint) {
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(0);
    }
  }

  .simplebar-wrapper {
    width: 100%;
  }

  .simplebar-content-wrapper {
    padding-right: 0;
  }

  .simplebar-mask {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%!important;
  }

  .modal__container {
    margin: 70px 10px 0;
    overflow: auto;
    position: relative;
    width: calc(100% - 20px);
    background: #D9E1E6;

    @media (min-width: $modal-breakpoint) {
      margin: 0;
      overflow: visible;
      height: auto;
      width: 100%;
    }
  }

  .modal__close {
    position: sticky;
    top: 20px;
    left: calc(100% - 31px);
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .modal__title {
    padding: 50px 56px 43px 56px;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 400;
    @include fontSize(22px);
    line-height: 122.727272727272727%;
    text-align: center;
    color: #000000;

    @media (min-width: $modal-breakpoint) {
      padding: 101px 56px 86px 56px;
    }
  }

  .modal__text {
    padding: 0 26px 52px;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(18px);
    line-height: 122.22222222222222%;
    color: #000000;
    text-align: left;

    @supports (-webkit-touch-callout: none) {
      padding: 0 26px 180px;
    }
  }

  @media (min-width: $modal-breakpoint) {
    .simplebar-content-wrapper {
      border-radius: 8px;
    }

    .simplebar-mask {
      max-width: calc(100% - 11px);
    }

    .simplebar-track.simplebar-vertical {
      right: 0;
    }

    .simplebar-scrollbar:before {
      background-color: #D9E1E6;
      opacity: 1;
    }
  }
}
