@import "../../css/lib/rem-calc";

.donations {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s;

  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid #fff;
  position: relative;

  &:hover {
    box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.05);
    transform: translateY(-2px);
  }

  &:active {
    color: #000;
    box-shadow: 0px 3px 0px #DFDFDF;
    outline:none;
    transform: translateY(0px);
  }

  &:visited {
    box-shadow: 0px -3px 0px #DFDFDF;
    transform: translate(0, 3px);
  }

  &.donations--active {
    background-color: #F6F5F1;
    color: #000;
    border: 1px solid #000;
    box-shadow: none;
    outline:none;
    min-height: 84px;

    .donations__text-light--custom {
      display: none;
    }

    .donations__input {
      display: block;
    }

    .donations__close {
      display: block;
    }
  }

  .donations__input {
    display: none;
    border: 0;
    width: 100%;
    text-align: center;
    appearance: textfield;
    outline: none;
    background-color: #F6F5F1;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    color: #000000;

    &:focus {
      border: 0;
    }
  }

  .donations__image {
    padding: 20px;
    height: 16px;
  }

  .donations__text-light {
    padding: 30px;
    text-align: center;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    color: #000000;
    letter-spacing: -0.01em;
    width: 100%;
  }

  .donations__close {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    top: calc(50% - 6px);
    right: 20px;
  }
}
