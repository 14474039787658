@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.article {
  display: grid;
  grid-template-columns: 1fr 50px 50px;
  grid-gap: 25px;
  background-color: #fff;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s;
  box-sizing: border-box;
  padding-left: 20px;

  @media (min-width: $default-breakpoint) {
    grid-template-columns: 50px 115px 50px 50px;
    grid-gap: 15px;
  }

  &.article--small {
    @media (min-width: $default-breakpoint) {
      grid-template-columns: 180px 50px 50px;
    }
  }

  &.article--selected {
    border: 1px solid #000;
    background-color: #F6F5F1;
    box-shadow: none;

    .article__button {
      background-color: #F6F5F1;
    }
  }

  .article__icon-container {
    display: none;

    @media (min-width: $default-breakpoint) {
      display: flex;
      align-items: center;
    }
  }

  .article__icon {
    width: 50px;
  }

  .article__main {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .article__name {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(18px);
    line-height: 120%;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .article__help-text {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(14px);
    line-height: 120%;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .article__prices {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .article__amount {
    font-weight: 500;
    color: #000;
  }

  .article__price {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(16px);
    line-height: 100%;
    text-align: right;
    letter-spacing: -0.02em;
  }

  .article__info {
    width: 12px;
    height: 12px;
  }

  .article__buttons {
    border-left: 1px solid #D2D2CE;
    box-sizing: border-box;
  }

  .article__button {
    height: 50px;
    width: 49px;
    border: 0;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #f3f3f3;
    }
  }

  .article__add,
  .article__substract {
    width: 13px;
    height: 13px;
  }

  .article__button--minus {
    border-top: 1px solid #D2D2CE;
  }
}
