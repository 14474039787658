@import "../../css/lib/rem-calc";

.checkbox {
  display: block;
  text-align: left;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 20px;
  padding: 0 20px;
  justify-content: start;

  .checkmark {
    height: 20px;
    width: 20px;
    background: #FFFFFF;
    box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.05);
    transition: box-shadow .3s;
    border: 1px solid #000;
  }

  &--error {
    .checkmark {
      border-color: #DD5A42;
    }

    .checkbox__label {
      &:before {
        content: var(--invalidText);
        color: #DD5A42;
        display: block;
      }
    }
  }

  .checkbox__title {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(18px);
    line-height: 111.11111111111111%;

    a {
      color: #000;
    }
  }

  .checkbox__help-text {
    display: block;
    text-align: left;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 400;
    @include fontSize(14px);
    line-height: 114.285714285714285%;

    a {
      color: #000;
    }
  }

  input {
    display: none;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.05);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background: #5E9B6C;
    border-color: #5E9B6C;
    box-shadow: 0px 3px 0px rgba(94, 155, 108, 0.25);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: ' ';
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
    background-image: url("../../assets/check.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    height: 8px;
    margin-top: 6px;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    // left: 9px;
    // top: 5px;
    width: 20px;
    height: 20px;
  }
}
