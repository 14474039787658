@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";
@import "../../css/lib/button-mixin";

/// @deprecated Use lib/_menu.
@keyframes menu {
  0% {
    opacity: 0;
    height: 0;
  }
  1% {
    height: 325px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 325px;
  }
}

/// @deprecated Use lib/_menu.
@keyframes menuReverse {
  0% {
    opacity: 1;
    height: 325px;
  }
  99% {
    height: 325px;
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.language-switch {
  width: 20px;
  height: 20px;

  &.language-switch--back {
    .language-switch__back {
      display: block;
    }

    .language-switch__chosen {
      display: none;

      @media (min-width: $default-breakpoint) {
        display: block;
      }
    }
  }

  .language-switch__chosen {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border .3s;
    z-index: 1;
    position: relative;

    &:hover {
      border: 2px solid #fff;
    }
  }

  .langauge-switch__header {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 17px;
    text-align: center;
    justify-content: center;

    @media (min-width: $default-breakpoint) {
      padding: 0;
    }
  }

  .langauge-switch__header-image {
    position: absolute;
    left: 17px;
    top: 17px;
    width: 24px;
    height: 24px;
    padding: 2px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border .3s;
    border-radius: 50%;
    box-sizing: border-box;

    &:hover {
      border: 2px solid #000;
    }

    @media (min-width: $default-breakpoint) {
      top: 0;
      left: 0;
    }
  }

  .language-switch__options {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    display: none;
    background-color: #f6f5f1;

    @media (min-width: $default-breakpoint) {
      width: 350px;
      height: 0px;
      padding: 25px;
      overflow: hidden;
      top: 10px;
      left: 10px;
      right: auto;
      border-radius: 5px;
      transition: opacity 300ms ease-out;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      opacity: 0;
      z-index: 0;
      box-shadow: 0px 0px 5px rgba(0,0,0,.5);
    }
  }

  // .language-switch__options--close {
  //   @media (min-width: $default-breakpoint) {
  //     animation: menu 300ms ease-in-out backwards reverse;
  //     // opacity: 1;
  //     // height: 325px;
  //   }
  // }

  .language-switch__options--open {
    display: block;

    @media (min-width: $default-breakpoint) {
      animation: menu 300ms ease-in-out forwards;
      display: flex;
      z-index: 2;
    }
  }

  .language-switch__options-container {
    padding: 8px;
    width: calc(100% -16px);
  }


  .language-switch__option {
    @include buttonBase;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    padding: 20px;
    align-items: center;
    text-decoration: none;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    cursor: pointer;

    @media (min-width: $default-breakpoint) {
      padding: 20px;
    }

    &.language-switch__option--selected {
      background-color: #F6F5F1;
      color: #000;
      border: 1px solid #000;
      box-shadow: none;
      outline:none;
    }
  }

  .language-switch__option-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }

  .language-switch__option-text {
    margin-left: 16px;
  }

  .language-switch__back {
    display: none;
    stroke: #000;
    cursor: pointer;

    @media (min-width: $default-breakpoint) {
      display: none !important;
    }
  }
}

.page-wrapper--background-image .language-switch__back {
  stroke: #fff;
}
