@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

.intro-image {
  padding: 130px 32px 0 32px;
  height: 360px;
  object-fit: cover;
  box-sizing: border-box;
  margin-bottom: -50px;

  @media (min-width: $default-breakpoint) {
    height: auto;
    padding: 30px 33px 38px;
    background: transparent !important;
    margin-bottom: 0;
  }

  &.intro-image--small-bottom {
    margin-bottom: -25px;
  }

  .intro-image__title {
    margin-top: 0;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(22px);
    line-height: 113.636363636363635%;
    text-align: center;

    &.intro-image__title--dark {
      color: #000;
    }
  }

  .intro-image__help-text {
    color: #5E9B6C;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(13.5375px);
    line-height: 100%;
    text-align: center;
  }
}

.page-wrapper--background-image .intro-image__title {
  color: #fff;

  @media (min-width: $default-breakpoint) {
    color: #000;
  }
}
