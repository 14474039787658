.article-group {
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  &.article-group--spacing {
    margin-bottom: 60px;
  }

  .article-group .intro__title {
    color: #000;
  }
}
