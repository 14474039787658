.blocks {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 16px;

  &.blocks--grid {
    grid-template-columns: 1fr 1fr;
  }
}
