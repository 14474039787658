@import "../../css/lib/responsive";

.loading {
  position: relative;
  width: 100%;
  height: 100vh;

  .loading__image {
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }

  .loading__text {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    margin-bottom: 0;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    line-height: 114%;
    text-align: center;

    @media (min-width: $default-breakpoint) {
      display: none;
    }
  }
}

.animation ~ .loading {
  display: none;
}
