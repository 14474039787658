@import "../../css/lib/rem-calc";
@import "../../css/lib/button-mixin";

.button {
  @include buttonBase;
  font-family: omnes-pro;
  font-style: normal;
  font-weight: 500;
  @include fontSize(17px);
  line-height: 111.76470588235294%;

  &.button--block {
    display: block;
    width: 100%;
  }

  &.button--colored {
    @include colouredButtonBase;
  }
}


