@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";

@keyframes loadIn {
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.home-intro {
  color: #fff;
  padding: 104px 63px 115px 63px;

  @media (min-width: $default-breakpoint) {
    color: #000;
    padding: 34px 63px 45px 63px;
  }

  .home-intro__container {
    background: radial-gradient(closest-side, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);

    @media (min-width: $default-breakpoint) {
      background: none;
    }
  }

  .home-intro__title {
    @include fontSize(32px);
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    line-height: 112.5%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    color: #F6F5F1;
    margin-bottom: 26px;
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translate(-50%, -50%);

    &.home-intro__title--animate {
      transform: translate(-50%, calc(50vh - 359%)) scale(2.25);
      animation: loadIn 1000ms 100ms ease-in-out forwards;

      @media (min-width: $default-breakpoint) {
        transform: none;
        animation: 0;
      }
    }

    @media (min-width: $default-breakpoint) {
      position: static;
      transform: none;
      color: #000;
      text-shadow: none;
    }
  }

  .home-intro__intro {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 400;
    @include fontSize(22px);
    line-height: 113.636363636363635%;
    padding-top: 26px;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    &.home-intro__intro--animate {
      opacity: 0;
      animation: fadeIn 1000ms 100ms ease-in-out forwards;

      @media (min-width: $default-breakpoint) {
        opacity: 1;
        animation: 0;
      }
    }

    @media (min-width: $default-breakpoint) {
      padding-top: 0;
      text-shadow: none;

    }
  }
}
