@import "../../css/lib/rem-calc";
@import "../../css/lib/responsive";
@import "../../css/lib/button-mixin";

.more {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 60px;

  &.more--coupon {
    margin-top: 45px;
    margin-bottom: 20px;
  }

  &:hover {
    .more__image {
      filter: drop-shadow(0px 5px 0px #DFDFDF);
      transform: translateY(-2px);
    }
  }

  &:active {
    .more__image {
      filter: drop-shadow(0px 3px 0px #DFDFDF);
      transform: translateY(0px);
    }
  }

  &.more--open {
    .more__image--up {
      display: block;
    }

    .more__image--down {
      display: none;
    }

    .more__text--less {
      display: block;
    }

    .more__text--more {
      display: none;
    }
  }

  .more__image--up {
    display: none;
  }

  .more__image--down {
    display: block;
  }

  .more__text--less {
    display: none;
  }

  .more__text--more {
    display: block;
  }

  .more--big {
    margin-bottom: 75px;
  }

  .more__image {
    @include buttonTransparent;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  .more__text {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(14px);
    line-height: 157.14285714285714%;
    color: #FFFFFF;
    margin-left: 10px;
    cursor: pointer;

    @media (min-width: $default-breakpoint) {
      color: #000;
    }
  }
}

.page-wrapper--darker .more__text,
.page-wrapper:not(.page-wrapper--background-image) .more__text {
    color: #474747;

    @media (min-width: $default-breakpoint) {
      color: #000;
    }
}
