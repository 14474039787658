@import "./lib/responsive";

html,
body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: omnes-pro;

    @media (min-width: $default-breakpoint) {
      touch-action: none;
    }
}

#app {
  font-family: omnes-pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

b {
  font-weight: 500;
}

.contain {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 0;
  position: relative;
}

input[type="button"],
button {
  touch-action: manipulation;
}
