@import "../../css/lib/rem-calc";

.select {
  border: 0;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  font-family: omnes-pro;
  position: relative;
  background: transparent;
  outline: none;

  .select__selected {
    box-sizing: border-box;
    text-align: left;

    background: #F6F5F1;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.05);
    transition: box-shadow .3s;
    margin-bottom: 3px;
    font-style: normal;
    font-weight: 500;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    &.select__selected--default {
      background-color: #FFFFFF;
      border: 0;
      font-style: normal;
      font-weight: normal;
      @include fontSize(17px);
      line-height: 129.411764705882355%;
      color: #000000;
    }

    &.select__selected--open {
      background: #F6F5F1;
      border: 0;
      box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.06);
    }
  }

  .select__display {
    background: transparent;
    border: 0;
    font-size: 1.0625rem;
    outline: none;
    padding: 14px 0 14px 19px;
    font-family: omnes-pro;
    width: 100%;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  .select___display-icon {
    display: block;
    padding: 14px 19px 14px 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .select__icon {
    width: 10px;
    height: 8px;
  }

  .select__dropdown {
    display: none;
    position: absolute;
    top: 50px;
    left: 10px;
    right: 10px;
    z-index: 2;
    max-height: 300px;
    overflow: auto;

    &.select__dropdown--open {
      display: block;
      margin-top: 3px;
    }
  }

  .select__item {
    padding: 14px 19px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 110px 10px;
    grid-gap: 17px;
    align-items: center;
    position: relative;

    &.select__item--hidden {
      display: none;
    }

    &:hover,
    &.select__item--highlight {
      background-color: #F6F5F1;
    }

    &:active,
    &.select__item--selected {
      border: 1px solid #000;
    }
  }

  .select__comment {
    font-family: omnes-pro;
    font-style: normal;
    @include fontSize(14px);
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #49525A;
    text-align: right;
  }

  .select__available {
    background-color: grey;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &[availability="low"] {
      background-color: #F1DF98;
    }

    &[availability="good"] {
      background-color: #5E9B6C;
    }
  }

  .select__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
