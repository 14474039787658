@use './responsive';

/// Creates an animated dropdown with state based on aria-expanded.
/// Animates using mask.
/// Respects auto height.
@mixin dropdown($display: block) {
  transition: all 300ms ease-in-out;
  mask: linear-gradient(to bottom, transparent 0%, red 0%, red 10%);
  mask-repeat: no-repeat;

  /// Hidden
  &[aria-expanded="false"] {
    animation: dropdown-hide 300ms forwards;
    visibility: hidden;
  }

  /// Visible
  &[aria-expanded="true"],&:not([aria-expanded]) {
    visibility: visible;
    animation: dropdown-show 300ms forwards;
  }
}

/// Animates slide in.
@keyframes dropdown-hide{
  0% {
    mask-size: 100% 100%;
  }
  100% {
    mask-size: 100% 0%;
  }
}


/// Animates slide out.
@keyframes dropdown-show{
  0% {
    mask-size: 100% 0%;
  }
  99% {
    mask-size: 100% 100%;
  }
  100% {
    mask: none;  // Remove mask so shadows etc. can pass through.
  }
}

