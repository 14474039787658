@import "../../css/lib/rem-calc";
@import "../../css/lib/button-mixin";

.feature {
  @include buttonBase;
  padding: 0;
  display: grid;
  grid-template-columns: 55px 1fr;
  grid-gap: 16px;
  overflow: hidden;
  align-items: start;
  height: 55px;

  .feature__image-container {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feature__image {
    object-fit: cover;
    width: 24px;
    height: 24px;
  }

  .feature__texts {
    display: table;
    height: 100%;
  }

  .feature__text {
    color: #000;
    text-decoration: none;
    font-family: omnes-pro;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    padding-right: 16px;
    display: table-cell;
    vertical-align: middle;
  }
}
