@import "./lib/responsive";

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    height: 52px;
    padding: 17px;
    box-sizing: border-box;
    z-index: 1;

    &.header--animate {
      transform: translateY(-100%);
      animation: header-load 450ms ease-in-out 200ms forwards;
    }

    @media (min-width: $default-breakpoint) {
      padding: 35px;
      height: 94px;
      animation: 0;
      transform: translateY(0);
      z-index: 2;
    }
}

.page-wrapper--background-image .header {
  background: linear-gradient(180deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,.5) 50%, rgba(0,0,0,0) 100%);

  @media (min-width: $default-breakpoint) {
    background: none;
  }
}

.page-wrapper--background-image .menu__burger {
    stroke: #fff;
}

@keyframes header-load {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
