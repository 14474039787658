@import "../../css/lib/responsive";

.upsell {
  position: relative;

  &.upsell--spacing {
    margin-bottom: 60px;
  }

  .upsell__image {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 436px;
    object-fit: cover;

    @media (min-width: $default-breakpoint) {
      display: none;
    }
  }

  .upsell__content {
    position: relative;
    height: 436px;

    @media (min-width: $default-breakpoint) {
      height: auto;
    }
  }

  .intro {
    padding: 142px 33px 38px;

    @media (min-width: $default-breakpoint) {
      padding: 30px 33px 38px;
    }
  }

  .splide__pagination {
    margin-bottom: 30px;
  }

  .splide__pagination__page {
    background: #8C8C8C;
    opacity: 1;
    height: 6px;
    width: 6px;
  }

  .splide__pagination__page.is-active {
    background: #ffffff;
    transform: none;
  }

  .button {
    margin-bottom: 83px;

    @media (min-width: $default-breakpoint) {
      margin-bottom: 0px;
    }
  }
}
