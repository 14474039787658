@import "../../css/lib/rem-calc";
@import "../../css/lib/button-mixin";

.footer {
  background-color: #49525A;
  box-sizing: border-box;
  padding: 26px 43px;

  .footer__title {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(20px);
    line-height: 110%;
    color: #fff;
    text-align: center;
    margin-bottom: 12px;
  }

  .footer__text {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: 500;
    @include fontSize(14px);
    line-height: 121.428571428571427%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-bottom: 25px;
    padding: 0 35px;
  }

  .footer__ctas {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    grid-gap: 31px;
    padding: 0 35px;
  }

  .footer__cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .footer__cta-image {
    width: 44px;
    height: 44px;
    margin-bottom: 9px;
    @include buttonTransparent;
  }

  .footer__cta-text {
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(14px);
    line-height: 121.428571428571427%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
  }
}
