@import "../../css/lib/rem-calc";

.payment-options-blocks {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 60px;

  &.checkbox--error {
    .payment-errors:before {
      content: var(--invalidText);
      color: #DD5A42;
    }
  }
}

.payment-options {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s;
  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid #fff;
  position: relative;

  &.payment-options--open {
    .payment-dropdown {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 20px;
  }

  &:hover {
    box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.05);
  }

  &:active,
  &.payment-options--selected {
    background-color: #F6F5F1;
    color: #000;
    border: 1px solid #000;
    box-shadow: none;
    outline:none;
  }

  .payment-options__image {
    padding: 20px;
    height: 16px;
  }

  .payment-options__text-light {
    padding: 20px;
    text-align: left;
    font-family: omnes-pro;
    font-style: normal;
    font-weight: normal;
    @include fontSize(17px);
    line-height: 129.411764705882355%;
    color: #000000;
    letter-spacing: -0.01em;
  }
}

.payment-dropdown {
  background-color: #FFFFFF;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 10;
  display: none;
  height: 300px;
  overflow: auto;

  .payment-dropdown__list {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
  }

  .payment-dropdown__item {
    padding: 20px;
    border: 1px solid #ffffff;
    cursor: pointer;

    &:hover {
      border: 1px solid #000000;
    }
  }
}
